<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '虚拟化编辑' : '虚拟化创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-alert message="虚拟化创建需要确保网络可达" type="info" banner show-icon style="margin-bottom: 12px"/>
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-divider orientation="left" style="margin-top: 0">基本配置</a-divider>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="groups">
        <group-select
          v-model="form.groups"
          source-type="hypervisor"
        ></group-select>
        <question-icon
          slot="label"
          title="所属群组"
          description="用于分组查询及告警配置"
        ></question-icon>
      </a-form-model-item>
      <a-form-model-item label="IP 地址" prop="monitorAddress">
        <a-input
          v-model="form.monitorAddress"
          :disabled="isUpdate"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="hypervisorType">
        <a-select
          v-model="form.hypervisorType"
          :disabled="isUpdate"
          :options="options"
        ></a-select>
      </a-form-model-item>
      <a-form-model-item label="代理" prop="proxyId">
        <a-select v-model="form.proxyId" :options="proxies"></a-select>
      </a-form-model-item>
      <a-divider orientation="left">监控配置</a-divider>
      <a-form-model-item
        label="用户名"
        :rules="[{
          message: '请输入用户名',
          required: true,
          trigger: 'blur'
        }]"
      >
        <a-input v-model="form.macro.user" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="密码"
        :rules="[{
          message: '请输入密码',
          required: true,
          trigger: 'blur'
        }]"
      >
        <a-input-password
          v-model="form.macro.password"
          :max-length="128"
          :visibility-toggle="!isUpdate"
        ></a-input-password>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getMonitorProxyList } from '@/api/monitor'
import {
  createHypervisor,
  getHypervisor,
  getHypervisorMonitorMacro,
  updateHypervisor
} from '@/api/hypervisor'
import QuestionIcon from '@/components/icon/QuestionIcon.vue'
import GroupSelect from '@/components/select/GroupSelect.vue'
import { hypervisorTypes } from '@/utils/const'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        groups: undefined,
        monitorAddress: '',
        hypervisorType: 'esxi',
        proxyId: '',
        macro: {}
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        monitorAddress: [
          {
            message: '请输入 IP 地址',
            required: true,
            trigger: 'blur'
          }
        ],
        hypervisorType: [
          {
            message: '请选择类型',
            required: true,
            trigger: 'change'
          }
        ],
        groups: [
          {
            message: '请选择所属群组',
            required: true,
            trigger: 'select'
          }
        ]
      },
      loading: false,
      options: hypervisorTypes.map(value => {
        return {
          title: this.$t(`hypervisor_type.${value}`),
          value
        }
      }),
      proxies: []
    }
  },
  mounted () {
    getMonitorProxyList().then(res => {
      this.proxies = res.data.data.map(item => {
        return {
          title: item.name,
          value: item.id
        }
      })
    })
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getHypervisor(id)
          .then(res => {
            const data = res.data
            this.form.id = data.id
            this.form.name = data.name
            this.form.groups = data.groups
            this.form.monitorAddress = data.monitor_address
            this.form.hypervisorType = data.hypervisor_type
            this.form.proxyId = data.proxy_id
          })
          .finally(() => {
            this.visible = true
          })
        getHypervisorMonitorMacro(id).then(res => {
          this.form.macro = res.data
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        groups: undefined,
        monitorAddress: '',
        hypervisorType: 'esxi',
        proxyId: '',
        macro: {}
      }
      this.$refs.form.resetFields()
      this.loading = false
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            name: this.form.name
          }
          if (this.form.groups) {
            params.group_ids = this.form.groups.map(group => group.id)
          }
          if (this.form.proxyId) params.proxy_id = this.form.proxyId
          if (Object.keys(this.form.macro).length !== 0) {
            params.macro = this.form.macro
          }
          if (this.isUpdate) {
            updateHypervisor(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              }).finally(() => {
                this.loading = false
              })
          } else {
            params.monitor_address = this.form.monitorAddress
            params.hypervisor_type = this.form.hypervisorType
            createHypervisor(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              }).finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
