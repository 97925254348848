var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"title":_vm.isUpdate ? '虚拟化编辑' : '虚拟化创建',"visible":_vm.visible,"width":800},on:{"close":_vm.closeDrawer}},[_c('a-alert',{staticStyle:{"margin-bottom":"12px"},attrs:{"message":"虚拟化创建需要确保网络可达","type":"info","banner":"","show-icon":""}}),_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-divider',{staticStyle:{"margin-top":"0"},attrs:{"orientation":"left"}},[_vm._v("基本配置")]),_c('a-form-model-item',{attrs:{"label":"名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"prop":"groups"}},[_c('group-select',{attrs:{"source-type":"hypervisor"},model:{value:(_vm.form.groups),callback:function ($$v) {_vm.$set(_vm.form, "groups", $$v)},expression:"form.groups"}}),_c('question-icon',{attrs:{"slot":"label","title":"所属群组","description":"用于分组查询及告警配置"},slot:"label"})],1),_c('a-form-model-item',{attrs:{"label":"IP 地址","prop":"monitorAddress"}},[_c('a-input',{attrs:{"disabled":_vm.isUpdate,"max-length":128},model:{value:(_vm.form.monitorAddress),callback:function ($$v) {_vm.$set(_vm.form, "monitorAddress", $$v)},expression:"form.monitorAddress"}})],1),_c('a-form-model-item',{attrs:{"label":"类型","prop":"hypervisorType"}},[_c('a-select',{attrs:{"disabled":_vm.isUpdate,"options":_vm.options},model:{value:(_vm.form.hypervisorType),callback:function ($$v) {_vm.$set(_vm.form, "hypervisorType", $$v)},expression:"form.hypervisorType"}})],1),_c('a-form-model-item',{attrs:{"label":"代理","prop":"proxyId"}},[_c('a-select',{attrs:{"options":_vm.proxies},model:{value:(_vm.form.proxyId),callback:function ($$v) {_vm.$set(_vm.form, "proxyId", $$v)},expression:"form.proxyId"}})],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("监控配置")]),_c('a-form-model-item',{attrs:{"label":"用户名","rules":[{
        message: '请输入用户名',
        required: true,
        trigger: 'blur'
      }]}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.macro.user),callback:function ($$v) {_vm.$set(_vm.form.macro, "user", $$v)},expression:"form.macro.user"}})],1),_c('a-form-model-item',{attrs:{"label":"密码","rules":[{
        message: '请输入密码',
        required: true,
        trigger: 'blur'
      }]}},[_c('a-input-password',{attrs:{"max-length":128,"visibility-toggle":!_vm.isUpdate},model:{value:(_vm.form.macro.password),callback:function ($$v) {_vm.$set(_vm.form.macro, "password", $$v)},expression:"form.macro.password"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }